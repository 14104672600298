import React from "react"

import Layout from "../../components/layout/layout"
import Seonew from "../../components/seo-new"
import BlogListSection from "../../components/sections/blog-list-section"

const BlogPage = () => (
  <Layout>
    <Seonew
      title="The Fast Web Blog"
    />
    <BlogListSection />
  </Layout>
)

export default BlogPage
