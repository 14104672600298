import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const BlogListSection = () => {
  const data = useStaticQuery(graphql`
    query blogPostsQuery {
      allPrismicBlogPost(
        sort: { order: DESC, fields: data___date }
        limit: 50
      ) {
        edges {
          node {
            uid
            data {
              title
              date(formatString: "MMMM Do YYYY", locale: "en-US")
            }
          }
        }
      }
    }
  `)

  const posts = data.allPrismicBlogPost.edges

  return (
    <>
      <div className="bg-gray-100 w-full px-4 pt-40 pb-32">
        <div className="w-full max-w-2xl mx-auto">
          <h1 className="text-3xl md:text-5xl text-center text-gray-800 font-semibold mt-6">The Fast Web Blog</h1>
          <p className="text-xl text-center mt-8">We are a small team of Jamstack and Gatsby specialists that never stops learning. We're sharing our experiences on how to make websites fast and secure for our clients.</p>
        </div>
      </div>
      <div className='bg-white pt-16 pb-24'>
        <div className='w-11/12 max-w-2xl mx-auto'>
          {posts.map(post => {
            return (
              <div className='mb-24'>
                <Link to={`/blog/${post.node.uid}`}>
                  <h2 className='text-gray-900 text-2xl md:text-4xl font-bold leading-tight hover:underline mt-8'>{post.node.data.title}</h2>
                </Link>
                <h2 className='mt-3 mb-8'>{post.node.data.date}</h2>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default BlogListSection